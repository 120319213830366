import React, { Component } from "react";
import { ButtonGroup, Button, DropdownButton, Dropdown } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

class File extends Component {
  constructor(props) {
    super(props);
    this.itemRef = React.createRef();
    this.linkRef = React.createRef();
    this.state = {
      selected: false,
    };
    this.invertSelected = this.invertSelected.bind(this);
  }
  invertSelected() {
    this.state.selected == false
      ? this.setState({ selected: true })
      : this.setState({ selected: false });
  }
  render() {
    let urlSource =
      "https://storage.googleapis.com/sonyremote-efdc0.appspot.com/";
    let urlSource_ =
      "https://storage.cloud.google.com/sonyremote-efdc0.appspot.com/5e91dc35df657d995c95a2a0.sql?folder&organizationId";
    let { name, type, date_created, id, _id } = this.props.files;
    let { handleAddTodo } = this.props;
    let re = /(?:\.([^.]+))?$/;
    return (
      <li
        style={{ display: "flex", justifyContent: "space-between" }}
        // onClick={this.invertSelected}
        onDoubleClick={(e) => {
          if (_id == null) {
            this.linkRef.current.click();
          }
        }}
        ref={this.itemRef}
        className={this.state.selected == true && "selected"}
      >
        {/* <ListGroup.Item value ={name} onClick={()=>handleAddTodo} type={type}> */}
        {/* <ListGroup.Item value ={name} type={type}> */}
        <div style={{ display: "flex" }}>
          {_id == null && (
            <Link to={`/dashboard/file/${id}`} ref={this.linkRef}></Link>
          )}
          {type !== "directory" ? (
            <i className="far fa-file mr-3 mt-2"></i>
          ) : (
            <i class="fas fa-folder mr-3 mt-2"></i>
          )}
          <div>
            <div>{name}</div>
            <div style={{ fontSize: "9pt" }}>{`${new Date(
              date_created
            ).toDateString()}`}</div>
          </div>
        </div>

        <DropdownButton
          id="dropdown-basic-button"
          title="•••"
          className="d-lg-none d-xl-none"
        >
          {_id != null && (
            <Dropdown.Item
              href={_id != null && `${urlSource + _id + re.exec(name)[0]}`}
              >
              <i className="fas fa-download"></i>&nbsp;&nbsp;Download
            </Dropdown.Item>
          )}
          <Dropdown.Item href="#/action-2">
            <i className="fas fa-share-square"></i>&nbsp;&nbsp;Share
          </Dropdown.Item>
          <Dropdown.Item href="#/action-2" onClick={()=>{_id==null?this.props.deleteItem(id):this.props.deleteItem(_id)}}>
            <i className="fas fa-trash"></i>&nbsp;&nbsp;Delete
          </Dropdown.Item>
        </DropdownButton>
        <ButtonGroup className="d-none d-lg-inline-block d-xl-inline-block">
          {_id != null && (
            <Button
              variant="primary"
              href={_id != null && `${urlSource + _id + re.exec(name)[0]}`}
            >
              <i className="fas fa-download"></i>
            </Button>
          )}
          <Button variant="primary" id="shareBtn">
            <i className="fas fa-share-square"></i>
          </Button>
          <Button variant="danger" onClick={()=>{_id==null?this.props.deleteItem(id):this.props.deleteItem(_id)}}>
            <i className="fas fa-trash"></i>
          </Button>
        </ButtonGroup>
      </li>
    );
  }
}

export default File;
