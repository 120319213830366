export const updateBreadcrumb = (item,name)=>{
    console.log(item);
    console.log(name);
    if(item){
        var obj= {
            pathname:item.pathname,
            name:name
        }
        
        return {
            type:'PUSH',
            newitem:obj
        }
    }else{
        return{
            type:'POP'
        }
    }
}
export const deleteBreadcrumb = (index)=>{
    if(index==0){
        return{
            type:'CLEAR'
        }
    }else{
        return{
            type:'REMOVE',
            index:index
        }
    }
}
// export default [updateBreadcrumb,clearBreadcrumb];