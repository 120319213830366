import React,{Fragment,useState} from "react";
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {auth} from '../services/auth'
// const endpoint = "/api/login"
function Register() {
  let history = useHistory();
  const [firstname,setFirstname]=useState('');
  const [lastname,setLastname]=useState('');
  const [username,setUsername]= useState('');
  const [password,setPassword]= useState('');
  const [isAuthenticated,setAuthenticated] = useState(false);
  const [showPrompt,setPrompt]=useState(false);
  const [prompt,changePrompt]=useState('Custom');
  const register = (e)=>{
    
    e.preventDefault();
    
    auth.register(firstname,lastname,username,password).then((res)=>{

      console.log(res)
      if(res.status==200){
        history.push('/login')
      }
      else{
        console.log(res);
        changePrompt(res.data.toString());
        setPrompt(true);
        setTimeout(()=>{setPrompt(false)},5000);
       
      }

      
    })
    
  }
  
  return (
    <Fragment>
      <form onSubmit={register}>
        {showPrompt && <div className="alert alert-warning" role="alert">{prompt}</div>}
        <div class="form-group">
          <label>First Name</label>
          <input
            type="name"
            className="form-control"
            // id="exampleInputEmail1"
            onChange={e=>setFirstname(e.target.value)}
           
          />
         
        </div>
        <div class="form-group">
          <label>Last Name</label>
          <input
            type="name"
            className="form-control"
            // id="exampleInputEmail1"
            onChange={e=>setLastname(e.target.value)}
           
          />
         
        </div>
        <div class="form-group">
          <label>Email address</label>
          <input
            type="email"
            className="form-control"
            // id="exampleInputEmail1"
            onChange={e=>setUsername(e.target.value)}
           
          />
         
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            // id="exampleInputPassword1"
            onChange={e=>setPassword(e.target.value)}
           
          />
        </div>
        {/* <div className="form-group form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck1"
          />
          <label className="form-check-label">
            Remember Me
          </label>
        </div> */}
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </Fragment>
  );
}

export default Register;
