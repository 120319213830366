import React, { useState, useEffect } from "react";
import axios from "axios";
import File from "../components/File";
import Upload from "../components/Upload";
//redux
import { connect } from "react-redux";
import { updateBreadcrumb, deleteBreadcrumb } from "../actions";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// import browserHistory from ('react-router');

import { Modal, Breadcrumb, Spinner, Button } from "react-bootstrap";

import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import BreadcrumbItem from "react-bootstrap/BreadcrumbItem";

function FileView(props) {
  const [files, setFiles] = useState([]);
  const [list, setList] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [currentName, setCurrentName] = useState(null);
  const [folderId, setFolderId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteDialog, setDeleteDialog] = useState(false);
  const [loaded, setLoaded] = useState(false);
  let history = useHistory();
  let dispatch = useDispatch();
  var previousRoute = useSelector((state) => state.breadcrumb.arr);
  useEffect(() => {
    console.log(props);
    refreshFileView();
  }, [props.fileId.fileId, props.uploadedFile]);
  function refreshFileView() {
    if (props.fileId.fileId) {
      var path = props.fileId.fileId;
      console.log(`Component Will  Recieve clickItem ${path}`);
      clickItem(path, "directory");
    } else {
      console.log("Component Will  Recieve else ");
      loadFiles();
    }
  }
  function handleDeleteClose() {
    setDeleteDialog(false);
  }
  function handleDelete() {
    let root = props.fileId.fileId ? "false" : "true";
    axios
      .delete(
        "/api/user/file?root=" + root,
        {
          headers: {
            "auth-token": localStorage.getItem("currentUser")
          },
          data:{ folderId: deleteId },
        }
      )
      .then((res) => {
        refreshFileView();
        setDeleteDialog(false);
        setDeleteId(null);
      })
      .catch((err) => console.log(err.response));
  }
  async function loadFiles() {
    try {
      let res = await axios.get("/api/user/directory", {
        headers: {
          "auth-token": localStorage.getItem("currentUser"),
        },
      });
      console.log(res.data);
      setDirectory(res.data, true);
    } catch (err) {
      console.log(err);
    }
  }
  function setDirectory(root, isRoot) {
    console.log(root);
    setCurrentName(root.name);
    setFolderId(root._id);
    let d = isRoot == false ? root.directory.root : root.root;
    let c = [];
    for (var i = 0; i < d.length; i++) {
      if (d[i]) {
        console.log(i);
        if (d[i].length > 1) {
          for (var j = 0; j < d[i].length; j++) {
            c.push({
              name: d[i][j].name,
              type: d[i][j].type,
              date_created: d[i][j].date_created,
              id: d[i][j].id,
              _id: d[i][j]._id,
            });
          }
        } else {
          c.push({
            name: d[i][0].name,
            type: d[i][0].type,
            date_created: d[i][0].date_created,
            id: d[i][0].id,
            _id: d[i][0]._id,
          });
        }
      }
    }
    setFiles(c);
  }
  useEffect(() => {
    console.log(history);
    if (history.location.pathname == "/dashboard/myfile") {
      dispatch(deleteBreadcrumb(0));
    } else if (history.action === "PUSH") {
      console.log(history.location);
      dispatch(updateBreadcrumb(history.location, currentName));
    } else if (history.action === "POP") {
      console.log(history.location);
      dispatch(updateBreadcrumb());
    }
  }, [currentName]);
  useEffect(() => {
    console.log(previousRoute);
    // console.log(breadcrumbs);
    let x = previousRoute.map((i) => {
      return (
        <Breadcrumb.Item>
          <Link
            item={breadcrumbs.length + 1}
            onClick={updateBreadcrumb}
            to={`${i.pathname}`}
          >
            {i.name}
          </Link>
        </Breadcrumb.Item>
      );
    });
    setBreadcrumbs(x);
  }, [previousRoute]);
  useEffect(() => {
    var files_ = files;
    files_.sort((x, y) => {
      if (x.type == "directory" && !y.type) {
        return -1;
      }
      if (y.type == "directory" && !x.type) {
        return 1;
      }
      if (x.date_created < y.date_created) {
        return 1;
      }
      if (x.date_created > y.date_created) {
        return -1;
      }
      return 0;
    });
    let l = files_.map((file) => {
      //   return <File clickItem={this.clickItem.bind(this)} files={file}></File>;
      return (
        <File clickItem={clickItem} deleteItem={deleteItem} files={file}></File>
      );
    });
    setList(l);
    setLoaded(true);
  }, [files]);
  async function deleteItem(id) {
    console.log(id);
    setDeleteId(id);
    setDeleteDialog(true);
  }
  async function clickItem(id, type) {
    clearFiles();
    console.log(`clickitem ${id}`);
    if (type == "directory") {
      let req = "/api/user/file/" + id;
      console.log(req);
      try {
        let res = await axios.get(req, {
          headers: {
            "auth-token": localStorage.getItem("currentUser"),
          },
        });
        console.log(res.data);
        setDirectory(res.data, false);
        console.log(res.data.root);
      } catch (err) {
        console.log(err);
      }
    }
  }
  function clearFiles() {
    setFiles([]);
  }
  function updateBreadcrumb_(e) {
    console.log(e.target);
    var item = e.target.attributes.getNamedItem("item").value;
    if (item == 0) {
      setBreadcrumbs([]);
    } else {
      let b = breadcrumbs.slice(0, item);
      setBreadcrumbs(b);
    }
  }
  return (
    <div className="fileView">
      <h2>{props.view}</h2>

      <Modal show={showDeleteDialog} onHide={handleDeleteClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Breadcrumb className="p-0 m-0">
        <Breadcrumb.Item>
          <Link item={0} onClick={updateBreadcrumb_} to={`/dashboard/myfile`}>
            Home
          </Link>
        </Breadcrumb.Item>
        {breadcrumbs}
      </Breadcrumb>
      {loaded == false ? (
        <ul className="p-0 m-0 table-responsive">
          <Spinner animation="border" role="status" className="center-block">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </ul>
      ) : (
        <ul className="p-0 m-0 table-responsive table-hover">{list}</ul>
      )}
    </div>
  );
}

export default FileView;
