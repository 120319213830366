import React, { useState, useEffect } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ListGroup from 'react-bootstrap/ListGroup'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import axios from "axios";



function FolderView(props) {
  const [files, setFiles] = useState("");
  const getFiles = async () => {
    try {
      let res = await axios.get("/api/user/directory", {
        headers: {
          "auth-token": localStorage.getItem("currentUser")
        }
      });
      var d = res.data.root;
      var c = [];
      console.log(res.data.root);
      for (var i = 0; i < d.length; i++) {
        if (d[i]) {
          console.log(i);
          if (d[i].length > 1) {
            for (var j = 0; j < d[i].length; j++) {
              c.push(<ListGroup.Item  value ={d[i][j].name}  onClick={(e)=>console.log(e.target.value)} type={d[i][j].type}>
                  <Row>
                    <Col>
                        {d[i][0].name} 
                    </Col>
                    <Col>
                        {d[i][0].type}
                    </Col>
                    <Col>
                        {d[i][0].extension}
                    </Col>
                </Row>
              </ListGroup.Item>);
            }
          } else {
            c.push(<ListGroup.Item value ={d[i][0].name} onClick={(e)=>console.log(e.target.value)} type={d[i][0].type}>
                <Row>
                    <Col>
                        {d[i][0].name} 
                    </Col>
                    <Col>
                        {d[i][0].type}
                    </Col>
                    <Col>
                        {d[i][0].extension}
                    </Col>
                </Row>
                </ListGroup.Item>);
          }
        }
      }
      setFiles(c);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getFiles();
  }, []);
  return (
    <div className="folderView">
        
            <Breadcrumb className = "p-0 m-0">
                <Breadcrumb.Item active href="/">Home</Breadcrumb.Item>
            </Breadcrumb>
            <ListGroup className = "p-0 m-0" variant="flush">{files}</ListGroup>

    </div>
  );
}

export default FolderView;
