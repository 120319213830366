const initialBreadcrumbState={
    arr:[]
}
const breadcrumbReducer = (state=initialBreadcrumbState,action)=>{
    console.log(action.newitem);
    console.log(action.type);
    const newState ={...state}
    console.log(newState.arr)
    switch(action.type){
        case 'PUSH':
           
            console.log(newState.arr);
            console.log(action.newitem);
            let index = null;
            for(var i=0;i<newState.arr.length;i++){
                if(newState.arr[i].pathname==action.newitem.pathname){
                    index=i;
                }
            }
            console.log(index);
            if(index==null){
                console.log("1");
                return{
                    ...state,
                    arr:[...state.arr, action.newitem]
                }
            }else{
                console.log("2");
                console.log(newState.arr.slice(0,index+1));
                return{
                    ...state,
                    arr:newState.arr.slice(0,index+1)
                }
            }
        case 'POP':
            return{
                ...state,
                arr:newState.arr.slice(0,newState.arr.length-1)
            }
        case 'CLEAR':{
            return{
                ...state,
                arr:[]
            }
        }
        case 'REMOVE':{
            return{
                ...state,
                arr:newState.arr.slice(0,action.index)
            }
        }
        default: return state 
    }
    
}

export default breadcrumbReducer;