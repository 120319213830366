import React from "react";
import Login from "./LoginPage/Login";
import Register from "./Register/Register";
import Dashboard from "./Dashboard/Dashboard";
import Logo from "./Logo.png";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { useHistory } from "react-router-dom";
import { PrivateRoute } from "./components/PrivateRoute";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
function App() {
  useEffect(() => {
    // history.listen(location=>{
    //   console.log(location);
    // })
  }, []);
  return (
    <div className="App">
      <Router history={useHistory}>
        <Route exact path="/">
          <Redirect to="/login"></Redirect>
        </Route>
        <PrivateRoute
          path={["/dashboard/file/:fileId", "/dashboard/myfile","/dashboard/"]}
          component={Dashboard}
        />
        {/* <PrivateRoute path={"/dashboard/myfile"} component={Dashboard} /> */}
        {/* <PrivateRoute path={"/dashboard/file/:fileid"} component={Dashboard} /> */}
        <Route exact path="/register">
          <Row>
            <Col className="d-none d-md-inline"></Col>
            <Col className="App-Login">
              <div className="h1 text-center">
                <img src={Logo} className="p-2 pb-3"></img>idealab
              </div>
              <Route path="/register" component={Register}></Route>
            </Col>
            <Col className="d-none d-md-inline"></Col>
          </Row>
        </Route>
        <Route exact path="/login">
          <Row style={{ margin: "0px" }}>
            <Col className="d-none d-sm-inline"></Col>
            <Col className="App-Login">
              <div className="h1 text-center">
                <img src={Logo} className="p-2 pb-3"></img>idealab
              </div>
              <Route path="/login" component={Login}></Route>
            </Col>
            <Col className="d-none d-sm-inline"></Col>
          </Row>
        </Route>
      </Router>
    </div>
  );
}

export default App;
