import React, { Component } from "react";
import {Toast} from 'react-bootstrap'
import ProgressBar from 'react-bootstrap/ProgressBar'
export class Progress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadedFiles: null,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({ uploadedFiles: props });
  }
  render() {
    return (
      <div className='my-3 p-sm-0'>
        {this.props.uploadOnGoing==true&&
        <Toast>
          <Toast.Header>
          <i className="fas fa-arrow-circle-up"></i>&nbsp;
            <strong className="mr-auto">Uploading...</strong>
          </Toast.Header>
          <Toast.Body>{this.props.uploadedFile}
          <ProgressBar className="mt-1 mb-2" now={this.props.uploadProgress} label={`${this.props.uploadProgress}%`}/>
          </Toast.Body>
          
        </Toast>}
      </div>
    );
  }
}

export default Progress;
