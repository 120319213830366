import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
// import FolderView from '../components/FolderView'
import FileView from "../components/FileView";

import FolderView from "../components/FolderView";
import { updateBreadcrumb } from "../actions/";
import Upload from "../components/Upload";
import Progress from "../components/Progress";
///redux
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Nav, Tab, Row, Col, Card } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Logo from '../Logo.png'
const Dashboard = (props) => {
  let history = useHistory();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [user, setUsername] = useState("");
  const [routes, setRoutes] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadOnGoing, setUploadOnGoing] = useState(false);
  const {
    match: { params },
  } = props;
  const dispatch = useDispatch();
  var previousRoute = useSelector((state) => state.breadcrumb.arr);
  //   console.log(newRoute);
  console.log(params);

  const logout = () => {
    localStorage.clear();
    history.push("/login");
  };
  const getDashboard = async () => {
    try {
      let res = await axios.get("/api/user/", {
        headers: {
          "auth-token": localStorage.getItem("currentUser"),
        },
      });
      console.log(res);
      setUsername(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getDashboard();
  }, []);
  return (
    <div className="dashboard">
      <nav className="navbar navbar-dark bg-dark">
        <div className="navbar-brand sm-12 ml-3">
          <a
            href="/dashboard/myfile"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <img src={Logo}></img>&nbsp;&nbsp;idealab
          </a>
        </div>
        <button onClick={logout} className="btn btn-primary">
          Logout
        </button>
      </nav>
      <div className="m-4">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col lg={3} md={4} sm={12}>
              <Card className="d-sm-none d-md-block mb-2">
                <Card.Body>
                <Row className="ml-2">
                <img src="https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fcnaca.ca%2Fwp-content%2Fuploads%2F2018%2F10%2Fuser-icon-image-placeholder.jpg&f=1&nofb=1" className="rounded-circle" style={{width:"40px"}}alt="Cinque Terre"/>
                <h6 className="mt-2 ml-2">{user}</h6></Row></Card.Body>
              </Card>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">My Files</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Recent</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">Shared</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fourth">Settings</Nav.Link>
                </Nav.Item>
              </Nav>
              <br />
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Upload
                    path={props.match.path}
                    fileId={params}
                    setUploadedFile={setUploadedFile}
                    setUploadProgress={setUploadProgress}
                    setUploadOnGoing={setUploadOnGoing}
                  ></Upload>
                  <Progress
                    uploadedFile={uploadedFile}
                    uploadProgress={uploadProgress}
                    uploadOnGoing={uploadOnGoing}
                  ></Progress>
                </Tab.Pane>
              </Tab.Content>
            </Col>
            <Col lg={6} md={5} sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <FileView
                    view={"My Files"}
                    fileId={params}
                    previousRoute={previousRoute}
                    uploadedFile={uploadedFile}
                  ></FileView>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  {/* <FileView2
                    view={"Recent"}
                    fileId={params}
                    previousRoute={previousRoute}
                    uploadedFile={uploadedFile}
                  ></FileView2> */}
                </Tab.Pane>
              </Tab.Content>
              {/* <FolderView/> */}
            </Col >
            <Col lg={3} md={3} sm={12}>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

export default Dashboard;
