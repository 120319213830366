import React from "react";
import axios from "axios";

const loginendpoint = "/api/login";
const registerendpoint = "/api/register";
async function login(username, password) {
  try {
    const res = await axios.post(
      loginendpoint,
      JSON.stringify({ email: username, password: password }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(res);
    localStorage.setItem("currentUser", res.data);
    return res;
  } catch (err) {
    console.log(err.response);

    return err.response
  }
}
async function register(firstname, lastname, username, password) {
  try {
    const res = await axios.post(
      registerendpoint,
      JSON.stringify({
        name: `${firstname} ${lastname}`,
        email: username,
        password: password,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(res);
    return res;
  } catch (err) {
    console.log(err.response);

    return err.response;
  }
}
function isAuthenticated() {
  var user = localStorage.getItem("currentUser");
  console.log(user);
  return user;
}
export const auth = { login,register, isAuthenticated };
