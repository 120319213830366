import React, { useRef, useState } from "react";
import axios from "axios";
// import Button from "react-bootstrap/Button";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  InputGroup,
  Row,
  Col,
  Modal,
  Form,
} from "react-bootstrap";

function Upload(props) {
  const uploadInput = useRef(null);
  const [showNewFolder, setShowNewFolder] = useState(false);
  const [folderName,setFolderName]=useState('');
  const [privacy,setPrivacy] =useState('Public');
  const [folderNameValid,setFolderNameValid]=useState(true);
  const createFolder=async(e)=>{
    if(folderName!=''){
      props.setUploadOnGoing(true);
      var root ="false";
      var body ={
        folderId:props.fileId.fileId,
        name:folderName,
        privacy:{
          private:privacy=="Public"?"false":"true"
        }
      }
      if(props.path=="/dashboard/myfile"){
        root="true";
        body = {
          name:folderName,
          privacy:{
            private:privacy=="Public"?"false":"true"
          }
        }
      }
      axios
      .post("/api/user/file?root="+root,body,{
        headers:{
          "auth-token":localStorage.getItem('currentUser')
        }
      })
      .then((response)=>{
        
        setFolderNameValid(true);
        setFolderName('');
        setShowNewFolder(false);
        props.setUploadOnGoing(false);
      })
      .catch(err=>{
        alert("Something went wrong");
        console.log(err.response);
      })

    }else{
      setFolderNameValid(false);
      setFolderName('');
    }
  }
  const closeModalHandler = (e) => {
    setShowNewFolder(false);
  };
  const showModalHandler = (e) => {
    setShowNewFolder(true);
  };
  const uploadHandler = (e) => {
    // props.setUploadedFile(e.target.files[0]);
    // alert(e.target.files[0]);
    if (e.target.files.length == 1) {
      if (props.path == "/dashboard/myfile") {
        let fd = new FormData();
        fd.append("file", e.target.files[0], e.target.files[0].name);
        props.setUploadOnGoing(true);
        props.setUploadedFile(e.target.files[0].name);
        axios
          .post("/api/upload?root=true", fd, {
            onUploadProgress: (progressEvent) => {
              console.log(
                "Upload Progress: " +
                  Math.round(
                    (progressEvent.loaded / progressEvent.total) * 100
                  ) +
                  "%"
              );
              props.setUploadProgress(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            },
            headers: {
              "auth-token": localStorage.getItem("currentUser"),
            },
          })
          .then((res) => {
            props.setUploadOnGoing(false);
            props.setUploadedFile(null);
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        let fd = new FormData();
        fd.append("file", e.target.files[0], e.target.files[0].name);
        console.log(props);
        fd.append("folderId", props.fileId.fileId);
        props.setUploadOnGoing(true);
        props.setUploadedFile(e.target.files[0].name);
        axios
          .post("/api/upload?root=false", fd, {
            onUploadProgress: (progressEvent) => {
              console.log(
                "Upload Progress: " +
                  Math.round(
                    (progressEvent.loaded / progressEvent.total) * 100
                  ) +
                  "%"
              );
              props.setUploadProgress(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            },
            headers: {
              "auth-token": localStorage.getItem("currentUser"),
            },
          })
          .then((res) => {
            props.setUploadedFile(null);
            props.setUploadOnGoing(false);
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  return (
    <div>
      <Modal
        show={showNewFolder}
        onHide={closeModalHandler}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Folder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Row>
              <Form.Label column lg={4}>
                Folder Name
              </Form.Label>
              <Col>
                <Form.Control isInvalid={!folderNameValid&&"invalid"} onChange={(e)=>{setFolderName(e.target.value)}} type="text" placeholder="Type Something..." />
                <Form.Control.Feedback type="invalid">
                  {"Invalid Name"}
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <br></br>
            <Form.Row>
              <Form.Label column lg={4}>
                Privacy
              </Form.Label>
              <Col>
                <Form.Control onChange={(e)=>{setPrivacy(e.target.value)}} as="select" custom>
                  <option>Public</option>
                  <option>Private</option>
                </Form.Control>
              </Col>
            </Form.Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModalHandler}>
            Close
          </Button>
          <Button variant="primary" onClick={createFolder}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      <Row>
        <Col md={12} lg={12} sm={4} xs={4} m>
          <DropdownButton as={ButtonGroup} title="Upload" className="btn-block">
            <input
              type="file"
              id="customFile"
              onChange={uploadHandler}
              ref={uploadInput}
              hidden
              multiple
            />
            <Dropdown.Item
              onClick={(e) => {
                uploadInput.current.click();
              }}
            >
              Upload File
            </Dropdown.Item>
            <Dropdown.Item>Upload Folder</Dropdown.Item>
          </DropdownButton>{" "}
        </Col>
        <Col md={12} lg={12} sm={4} xs={4}>
          <Button variant="light" block onClick={showModalHandler}>
            New Folder
          </Button>{" "}
        </Col>
        <Col md={12} lg={12} sm={4} xs={4}>
          <Button variant="light" block>
            Share
          </Button>{" "}
        </Col>
      </Row>
    </div>
  );
}

export default Upload;
