import React,{Fragment,useState} from "react";
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {auth} from '../services/auth'
// const endpoint = "/api/login"
function Login() {
  let history = useHistory();
  var  colors  = {warning:'alert-warning',danger:'alert-danger'}
  const [username,setUsername]= useState('');
  const [password,setPassword]= useState('');
  const [isAuthenticated,setAuthenticated] = useState(false);
  const [showPrompt,setPrompt]=useState(false);
  const [prompt,changePrompt]=useState('Custom');
  const [promptColor,changePromptColor]=useState(null)
  const authenticate = (e)=>{
    
    e.preventDefault();
    
    auth.login(username,password).then((res)=>{

      console.log(res)
      if(res.status==200){
        history.push('/dashboard/myfile')
      }
      else{
        var reply =null;
        if(res.status==500){
            reply = res.statusText
            changePromptColor(colors.danger)
        }else if(res.status==400){
            reply = res.data.status
            changePromptColor(colors.warning)
        }else{
            reply = "No Internet Connection"
            changePromptColor(colors.danger)
        }
        changePrompt(reply);
        setPrompt(true);
        setTimeout(()=>{setPrompt(false)},5000);
       
      }

      
    })
    
  }
  
  return (
    <Fragment>
      <form onSubmit={authenticate}>
        {showPrompt && <div className={`alert ${promptColor}`}role="alert">{prompt}</div>}
        <div class="form-group">
          <label>Email address</label>
          <input
            type="email"
            className="form-control"
            // id="exampleInputEmail1"
            onChange={e=>setUsername(e.target.value)}
           
          />
         
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            // id="exampleInputPassword1"
            onChange={e=>setPassword(e.target.value)}
           
          />
        </div>
        <div className="form-group form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck1"
          />
          <label className="form-check-label">
            Remember Me
          </label>
        </div>
        <div className="form-group form-register">
          <label className="form-check-label">
            Not yet registered? 
            <a href="/register">Click Here</a>
          </label>
        </div>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </Fragment>
  );
}

export default Login;
